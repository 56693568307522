// Budget Chart
var budgetData = {
    labels: ['Voedsel', 'Entertainment', 'Gastvrijheid', 'Diversen'],
    datasets: [{
        label: 'Begroting',
        data: [26, 13, 53, 8],
        backgroundColor: [
            'rgb(2, 16, 63)',
            'rgb(4, 48, 183)',
            'rgb(54, 162, 235)',
            'rgb(48 86 211)'
        ],
        borderColor: [
            'rgb(2, 16, 63)',
            'rgb(4, 48, 183)',
            'rgb(54, 162, 235)',
            'rgb(48 86 211)'
        ],
        borderWidth: 1
    }]
};

var ctx = document.getElementById('budgetChart').getContext('2d');
var budgetChart = new Chart(ctx, {
    type: 'doughnut',
    data: budgetData,
    options: {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: 'right'
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var label = data.labels[tooltipItem.index] || '';
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return label + ': ' + value + '%';
                }
            }
        }
    }
});

//Contractor Chart
var workProgressData = {
    labels: ['Uitgraven', 'Fundering'],
    datasets: [{
        label: 'Voltooiingspercentage',
        data: [20, 40],
        backgroundColor: [
            'rgb(48 86 211)',
            'rgb(48 86 211)',
        ],
        borderColor: [
            'rgb(48 86 211)',
            'rgb(48 86 211)',
        ],
        borderWidth: 1
    }]
};

var ctx = document.getElementById('workProgressChart').getContext('2d');
var workProgressChart = new Chart(ctx, {
    type: 'bar',
    data: workProgressData,
    options: {
        scales: {
            y: {
                ticks: {
                    beginAtZero: true,
                    max: 100
                }
            }
        }
    }
});

//Patient Chart
var ctx = document.getElementById('bloodPressureChart').getContext('2d');
var bloodPressureChart = new Chart(ctx, {
    type: 'line',
    data: {
        labels: ['01.01.2024', '02.01.2024', '03.01.2024', '04.01.2024', '05.01.2024'], // Beispiel-Daten
        datasets: [{
            label: 'Bloeddruk',
            data: [120, 130, 110, 100, 110],
            backgroundColor: 'rgb(48 86 211)',
            borderColor: 'rgb(48 86 211)',
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            y: {
                ticks: {
                    beginAtZero: true
                }
            }
        }
    }
});

//Sales Chart
var salesData = {
    labels: ['Broeken', 'Shorts', 'T-shirts', 'Schoenen', 'Hoeden'],
    datasets: [{
        label: 'Verkopen per artikel',
        data: [150, 300, 200, 400, 250],
        backgroundColor: [
            'rgb(48 86 211)',
            'rgb(48 86 211)',
            'rgb(48 86 211)',
            'rgb(48 86 211)',
            'rgb(48 86 211)'
        ],
        borderColor: [
            'rgb(48 86 211)',
            'rgb(48 86 211)',
            'rgb(48 86 211)',
            'rgb(48 86 211)',
            'rgb(48 86 211)'
        ],
        borderWidth: 1
    }]
};

var ctx = document.getElementById('salesChart').getContext('2d');
var salesChart = new Chart(ctx, {
    type: 'bar',
    data: salesData,
    options: {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }
});